const BASE_LINK = "https://no-more-boredom.vercel.app/v1";

const options = {
	method: 'GET',
	headers: {
		
	}
};

const fetcher = async (url) => {
    let response = [];
    try {
        const resp = await fetch(BASE_LINK + url, options);
        if (!resp.ok) {
            throw new Error("Error: " + resp.status);
        }
        response = await resp.json();
        return response;

    } catch (err) {
        console.log(err);
        return response;
    }

}

export const getJokes = async (page, query = '') => {
    const jokes = await fetcher("/jokes?page=" + page + '&q=' + query);
    return jokes;
}

export const getJokeById = async (id) => {
    const joke = await fetcher("/jokes/" + id);
    return joke;
}

export const getRiddles = async (page, query = '') => {
    const riddles = await fetcher("/riddles?page=" + page + '&q=' + query);
    return riddles;
}

export const getRiddleById = async (id) => {
    const riddle = await fetcher("/riddles/" + id);
    return riddle;
}